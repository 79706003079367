<template>
  <div v-show="!invisible" class="turnstile">
    <div ref="turnstile"></div>
  </div>
</template>

<script>
const turnstileSrc = "https://challenges.cloudflare.com/turnstile/v0/api.js";
const turnstileLoadFunction = "cfTurnstileOnLoad";
let turnstileState =
  typeof window !== "undefined" ? (window.turnstile !== undefined ? "ready" : "unloaded") : "unloaded";
let turnstileLoad = {
  resolve: () => {},
  reject: () => {},
};
export default {
  name: "Turnstile",
  emits: ["update:modelValue", "error", "unsupported"],
  props: {
    siteKey: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    resetInterval: {
      type: Number,
      default: 295 * 1000,
    },
    size: {
      type: String,
      default: "normal",
    },
    theme: {
      type: String,
      default: "auto",
    },
    language: {
      type: String,
      default: "auto",
    },
    action: {
      type: String,
      default: "",
    },
    appearance: {
      type: String,
      default: "always",
    },
    renderOnMount: {
      type: Boolean,
      default: true,
    },
    invisible: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  data() {
    return {
      resetTimeout: undefined,
      widgetId: undefined,
    };
  },
  computed: {
    turnstileOptions() {
      return {
        sitekey: this.siteKey,
        theme: this.theme,
        language: this.language,
        size: this.size,
        callback: this.callback,
        action: this.action,
        appearance: this.appearance,
        "error-callback": this.errorCallback,
        "unsupported-callback": this.unsupportedCallback,
      };
    },
  },
  methods: {
    unsupportedCallback() {
      this.$emit("unsupported");
    },
    errorCallback(code) {
      this.$emit("error", code);
    },
    callback(token) {
      this.$emit("update:modelValue", token);
      this.startResetTimeout();
    },
    reset() {
      if (window.turnstile) {
        this.$emit("update:modelValue", "");
        window.turnstile.reset();
      }
    },
    remove() {
      if (this.widgetId) {
        window.turnstile.remove(this.widgetId);
        this.widgetId = undefined;
      }
    },
    render() {
      this.widgetId = window.turnstile.render(this.$refs.turnstile, this.turnstileOptions);
    },
    startResetTimeout() {
      this.resetTimeout = setTimeout(() => {
        this.reset();
      }, this.resetInterval);
    },
  },
  async mounted() {
    const turnstileLoadPromise = new Promise((resolve, reject) => {
      turnstileLoad = { resolve, reject };
      if (turnstileState === "ready") resolve(undefined);
    });
    window[turnstileLoadFunction] = () => {
      turnstileLoad.resolve();
      turnstileState = "ready";
    };
    const ensureTurnstile = () => {
      if (turnstileState === "unloaded") {
        turnstileState = "loading";
        const url = `${turnstileSrc}?onload=${turnstileLoadFunction}&render=explicit`;
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.addEventListener("error", () => {
          turnstileLoad.reject("Failed to load Turnstile.");
        });
        document.head.appendChild(script);
      }
      return turnstileLoadPromise;
    };
    await ensureTurnstile();
    if (this.renderOnMount) {
      this.render();
    }
  },
  beforeUnmount() {
    this.remove();
    clearTimeout(this.resetTimeout);
  },
};
</script>

<style lang="scss">
.turnstile {
  width: 300px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
  box-shadow:
    inset 1px 1px #666,
    inset -1px -1px #666;
  position: relative;

  .cf-turnstile-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px !important;
    height: 65px !important;
  }

  > div {
    translate: 0 5px;
  }
}
</style>
